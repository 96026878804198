<template>
  <div v-if="loading">Loading...</div>
  <div v-else-if="!person">Person not found</div>
  <div v-else>
    <!-- general info -->
    <div style="display: flex; justify-content: space-between">
      <div>
        <h2>
          {{ person.name + ' ' + person.lastname?.[0] }}
        </h2>
        <span class="link" style="font-size: 14px" @click="$router.push(`/v2/tree/${id}`)"
          >atrás</span
        >
        |
        <span class="link" style="font-size: 14px" @click="$router.push(`/v2/edit/${id}`)"
          >editar</span
        >
      </div>

      <AvatarWithEditor :person="person" />

      <div v-if="false" style="min-height: 100px; min-width: 100px; border: 1px solid #ccc">
        <v-img v-if="person.avatarUrl" :src="person.avatarUrl"></v-img>
        <v-icon v-else>mdi-account</v-icon>
      </div>
    </div>

    <!-- detalles -->
    <div>
      <!-- birthday -->
      <p v-if="person.birthday" style="margin-bottom: 10px">
        <b>Cumpleaños:</b>
        {{
          new Date(person.birthday).toLocaleDateString('es-ES', { day: 'numeric', month: 'long' })
        }}
      </p>
      <!-- deathdate -->
      <p v-if="person.deathdate" style="margin-bottom: 10px">
        <b>Falleció:</b>
        {{
          new Date(person.deathdate).toLocaleDateString('es-ES', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })
        }}
      </p>

      <!-- aniversary -->
      <div v-if="person?.relatives?.filter((x) => x.type == 'spouse').length">
        <div v-for="rel in person.relatives.filter((x) => x.type == 'spouse')" :key="rel.id">
          <b>Aniversario: </b>
          {{ rel.date || '???' }}
        </div>
      </div>
      <p style="margin: 20px 0px">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, suscipit. Molestias, maxime
        sit. Velit, suscipit architecto veritatis expedita aspernatur nobis sit sint soluta ad
        consequuntur similique at, minima beatae possimus!
      </p>
    </div>

    <div>
      <v-btn @click="bottom_sheet = true" flat>test</v-btn>
    </div>

    <!-- bottom sheet -->
    <v-bottom-sheet v-model="bottom_sheet" style="max-width: 500px">
      <div
        style="
          min-height: 300px;
          padding: 20px;
          border-radius: 15px 15px 0 0;
          background-color: white;
          position: relative;
          overflow-y: auto;
        "
      >
        <div style="text-align: right; position: absolute; top: 5px; right: 10px">
          <v-btn icon flat size="s" @click="bottom_sheet = false">
            <v-icon size="s">mdi-close</v-icon>
          </v-btn>
        </div>

        <div>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit harum unde quo error omnis
          labore vero perferendis, nam asperiores ratione a, repudiandae amet, quidem mollitia.
          Laborum velit cupiditate repellat dolor.
        </div>
      </div>
    </v-bottom-sheet>

    <!-- timeline -->
    <div style="height: 30px"></div>
    <h2>Timeline</h2>
    <v-timeline dense side="end">
      <v-timeline-item v-for="(year, i) in years" :key="i" :color="year.color" small d>
        <template v-slot:opposite>
          <span :class="`headline font-weight-bold ${year.color}--text`" v-text="year.year"></span>
        </template>
        <div class="py-4">
          <h3 :class="`headline font-weight-light mb-4 ${year.color}--text`">Lorem ipsum</h3>
          <div>
            Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut,
            sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando
            voluptatibus, vix an salutandi sententiae.
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import AvatarWithEditor from './components/AvatarWithEditor.vue'
//const { v4: uuidv4 } = require('uuid')
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  components: {
    AvatarWithEditor,
  },
  data() {
    return {
      loading: false,
      person: {},
      bottom_sheet: false,
      years: [
        {
          color: 'cyan',
          year: '1960',
        },
        {
          color: 'green',
          year: '1970',
        },
        {
          color: 'pink',
          year: '1980',
        },
        {
          color: 'amber',
          year: '1990',
        },
        {
          color: 'orange',
          year: '2000',
        },
      ],
    }
  },
  watch: {
    id() {
      this.fetchData()
    },
  },
  methods: {
    async fetchPerson(id) {
      const url = `https://3qvcuoiu2tcqghh73a7jzeu4vq0rsiqq.lambda-url.us-east-1.on.aws/person?id=${id}`
      let x = await fetch(url, {
        method: 'GET',
        headers: { Authorization: localStorage.token },
      })

      if (x.ok) {
        return await x.json()
      } else {
        return null
      }
    },
    async fetchData() {
      this.loading = true
      let x = await this.fetchPerson(this.id)
      this.loading = false
      if (x) {
        this.person = { ...x }
      } else {
        this.person = { id: this.id, name: '', lastname: [''], relatives: [] }
      }
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped></style>
